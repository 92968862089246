import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
  HStack,
  Text,
  VStack,
  useMediaQuery
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'

export const CustomTabs = ({ tabData = [] }) => {
  const [selectedTab, setSelectedTab] = useState({
    index: 0,
    left: 0
  })
  const firstElemRef = useRef()

  const [isMobile] = useMediaQuery('(max-width: 1000px)')

  useEffect(() => {
    if (firstElemRef.current) {
      const el_rect = firstElemRef.current?.getBoundingClientRect()
      setSelectedTab({
        index: 0,
        xPos: el_rect.left - 15
      })
    }
  }, [firstElemRef])

  function changeTab (tabIndex) {
    // if (element) {
    //   const el_rect = element?.getBoundingClientRect()
    //   console.log(el_rect.left)
    setSelectedTab({
      index: tabIndex
    })
    // }
  }

  const _tabScreen = tabData[selectedTab.index].screen || {}

  return (
    <Grid
      w='full'
      h='full'
      gridTemplateRows={{ base: '1fr' }}
      gridTemplateColumns={{ lg: 'repeat(3, 1fr)', base: '1fr' }}
      gridRowGap={{ lg: '70px', base: '10px' }}
      gridColumnGap={'10px'}
      display={{ lg: 'grid', sm: 'inline' }}
    >
      {/* {!isMobile && (
        <HStack
          gridArea={'1 / 1 / 2 / 4'}
          justify={'center'}
          align={'center'}
          pos='relative'
        >
          <Box
            h={{ md: '35px', base: '30px' }}
            w={{ md: '120px', base: '120px' }}
            // bgGradient={'linear(to-r, #7551E9, #3FBEC3)'}
            bg='white'
            pos='absolute'
            borderRadius={'md'}
            transition='all 300ms'
            left={selectedTab.xPos}
          />
          {tabData.map((single, index) => (
            <Text
              ref={index == 0 ? firstElemRef : null}
              zIndex={'1'}
              id={`custom_tab_label_${index}`}
              fontWeight='semibold'
              fontSize={{ md: 'sm', base: 'xs' }}
              color={selectedTab.index == index ? 'black' : 'gray.500'}
              transition={'all 300ms'}
              cursor='pointer'
              _hover={{
                color: selectedTab.index == index ? 'black' : 'white'
              }}
              w='120px'
              textAlign={'center'}
              onClick={ev => changeTab(ev.target, index)}
            >
              {single.label}
            </Text>
          ))}
        </HStack>
      )} */}
      {/* <VStack
        h={{ lg: 'full', base: 'max-content' }}
        w='full'
        justify='center'
        pl={{ lg: '10', base: '0' }}
        pr={{ lg: '5', base: '0' }}
        gridArea={{ lg: '2 / 1 / 3 / 2' }}
        color='white'
        align={{ lg: 'flex-start', base: 'center' }}
        pb={{ lg: '0', base: '10' }}
      >
        <Text fontSize='xx-large' fontWeight='bold'>
          {_tabScreen?.title}
        </Text>
        <Text fontSize='md'>{_tabScreen?.description}</Text>
      </VStack> */}
      <Accordion borderBottomWidth={'none'} defaultIndex={0}>
        {tabData.map((tabItem, tabIndex) => {
          return (
            <AccordionItem borderWidth={'0'} borderBottomWidth={'0 !important'}>
              {({ isExpanded }) => (
                <VStack
                  align={'stretch'}
                  bg={isExpanded ? '#242526' : 'transparent'}
                  borderRadius={'md'}
                  borderWidth={'thin'}
                  borderColor={isExpanded ? '#1f7964' : 'transparent'}
                >
                  <AccordionButton
                    _hover={{
                      bg: 'auto'
                    }}
                    onClick={() => changeTab(tabIndex)}
                  >
                    <Text
                      as='span'
                      flex='1'
                      textAlign='left'
                      fontSize={'medium'}
                      fontWeight={'semibold'}
                    >
                      {tabItem.screen.title}
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                  {/* </Text> */}
                  <AccordionPanel
                    pb={4}
                    w='full'
                    fontSize={'sm'}
                    fontWeight={'regular'}
                  >
                    {tabItem.screen.description}
                  </AccordionPanel>
                </VStack>
              )}
            </AccordionItem>
          )
        })}
      </Accordion>
      {_tabScreen.usp &&
        _tabScreen.usp.map((usp, uspIndex) => (
          <VStack
            w='full'
            h={{ lg: 'full', base: 'max-content' }}
            gridArea={uspIndex == 0 ? '1 / 2 / 2 / 3' : '1 / 3 / 2 / 4'}
            bg='#242526'
            py='5'
            px='5'
            align={'flex-start'}
            // borderWidth={'thin'}
            // borderColor={'#41BAC440'}
            transition={'all 300ms'}
            borderRadius='md'
            color={'white'}
            mb={{ lg: '0', base: '5' }}
            _hover={{ transform: 'scale(1.02)' }}
          >
            <VStack
              h='60px'
              w='60px'
              color={'#764FEA'}
              fontSize={'30px'}
              boxShadow={'0px 5px 15px 4px rgba(0,0,0,0.25)'}
              // bgGradient={'linear(to-tr, #764FEA, #31DAB9)'}
              bg='#2c2e30'
              justify={'center'}
              align='center'
              borderRadius={'md'}
            >
              {usp.icon}
            </VStack>
            <VStack
              h='full'
              w={'full'}
              justify={'flex-start'}
              align={'flex-start'}
              pt='5'
            >
              <Text fontSize='lg' fontWeight={'semibold'}>
                {usp.title}
              </Text>
              <Text fontSize='sm'>{usp.description}</Text>
            </VStack>
          </VStack>
        ))}
    </Grid>
  )
}

import { extendTheme } from "@chakra-ui/react";
import "@fontsource/poppins";

export const containerBreakpoints = {
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

const theme = extendTheme({
  fonts: {
    poppins: `'Poppins', sans-serif`,
  },
  colors: {
    darkColor: {
      900: "#020202",
      // ...
      800: "#0A0A0A",
      600: "#1F1F1F",
    },
    green: {
      900: "#12755F",
      800: "#0BAB64",
      700: "#1EC29D",
      600: "#95FF00",
      500: "#78CC51",
      400: "#ACE069",
    },
  },
  body: {
    scrollBehavior: "smooth",
  },
});

export default theme;

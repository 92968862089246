import { Box, Button, Image, List, ListItem } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdOutlineMenu } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import logo from "../../assets/logo.png"
import "./navbar.css"
const Navbar = () => {
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [isCollapse, setIsCollapse] = useState(false);

    const location = useLocation();
    
    useEffect(()=> {
      const parent = document.getElementById("parent")
      const handleTrackingscroll = ()=> {
        const srolling = window.scrollY
         if(srolling > 100){
          setIsCollapse(true)
        }else {
           setIsCollapse(false)

         }
      }
        
        const  handleTrackingScreen = ()=> {
            const screen = window.innerWidth;

            if(screen < 768){
                setIsMobileScreen(true);
            }else{
                setIsMobileScreen(false);

            }


        }
        handleTrackingScreen()

        window.addEventListener("scroll", handleTrackingscroll);
    window.addEventListener("resize", handleTrackingScreen);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleTrackingscroll);
      window.removeEventListener("resize", handleTrackingScreen);
    };
    },[])
  return (
    <Box
      as="nav"
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={{base: "flex-start", md: "center"}}
      px={{base: "2rem", md: "5rem"}}
      py={isCollapse ? "1.2rem" : "1.5rem"}
      color={"#fff"}
      position={"fixed"}
      bg={isCollapse ? "darkColor.900" : "transparent"}
      transition={"all 0.5s ease-in-out"}
      w={"100%"}
      zIndex={"99999"}
      opacity={(location.pathname === "/" && !isCollapse) ? "0": "1" }
      visibility={(location.pathname === "/" && !isCollapse) ? "hidden": "visible" }
    >
      <Link to={"/"}><Image src={logo} maxW={{base: "40px", md: "60px"}} /></Link>

      <Box display={{base: "block", md: "none"}} fontSize={"1.5rem"} alignSelf={"center"} bg={"transparent"} color={"#fff"} onClick={()=> setIsOpenMenu(!isOpenMenu)}>{!isOpenMenu ? <MdOutlineMenu /> : <IoMdClose  /> }</Box>


      <List
        display={"flex"}
        flexGrow={"1"}
        flexDirection={{base: "column", md: "row"}}

        justifyContent={"flex-end"}
        alignItems={"centr"}
        gap={"1.6rem"}
        fontWeight={"500"}
        fontSize={"1rem"}
        lineHeight={"1rem"}
        position={{base: "absolute",md: "static"}}
        top={"100%"}
        right={"0"}
        bg={{base: "darkColor.600", md: "transparent"}}
        py={{base: (isOpenMenu && isMobileScreen) ? "2rem": "0" , md: ".9rem"}}
        px={{base: "2rem", md: "0rem"}}
        w={{base: "100%", md: "fit-content"}}
        borderRadius={"10px"}
        h={{base: (isOpenMenu && isMobileScreen) ?"290px" : "0", md: "fit-content"}}
        transition={"all 0.5s ease-in-out"}
        overflow={"hidden"}
      >
        <ListItem>
          <NavLink to={"/tokenomics"} onClick={()=> setIsOpenMenu(false)} >Tokenomics</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to={"/roadmap"} onClick={()=> setIsOpenMenu(false)}>Roadmap</NavLink>
        </ListItem>
        <ListItem>
          <NavLink target="_blank" to={"https://docs.google.com/presentation/d/1jq4CtqK0bF-sQc6H0PJOIaYmEDOjZB0kYuvfWxhchv4/edit#slide=id.g4dfce81f19_0_45"} onClick={()=> setIsOpenMenu(false)}>Pitch Deck</NavLink>
        </ListItem>
        <ListItem>
          <NavLink target="_blank" to={"https://hitmakr.gitbook.io/hitmakr-whitepaper"} onClick={()=> setIsOpenMenu(false)} >Whitepaper</NavLink>
        </ListItem>
        <Button size={"2xl"} display={{base: "block", md: "none"}} bg="#fff" w="100%" borderRadius="20px" py={".7rem"}>Join Waitlist</Button>
      </List>

      
    </Box>
  );
};

export default Navbar;

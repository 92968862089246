import React from "react";
import { containerBreakpoints } from "../../theme";
import { Box, Center, Container, Heading, Text } from "@chakra-ui/react";

const Taxes = () => {
  return (
    <Container maxW={containerBreakpoints} color="white" mb={"5.5rem"}>
      {/* Heading area */}
      <Center mb="4.8rem">
        <Heading as="h2" color="white" fontSize="2.7rem" lineHeight={"1.7rem"}>
          Taxes
        </Heading>
      </Center>
      {/* content area */}
      <Center fontFamily="poppins">
        <Box
          bg={{ base: "darkColor.600", md: "darkColor.800" }}
          borderRadius="12px"
          p={"1rem"}
          pt={"2.5rem"}
          minW={{ base: "100%", md: "321px" }}
          textAlign={"center"}
          mx={".8rem"}
        >
          <Heading
            as="h3"
            color="white"
            mb="1rem"
            fontSize="1.2rem"
            lineHeight={"1.2rem"}
          >
            Operations
          </Heading>
          <Text
            fontWeight="500"
            fontSize="4.5rem"
            lineHeight="4.8rem"
            mb="1.5rem"
            display="inline-block"
          >
            4%
          </Text>
        </Box>
      </Center>
    </Container>
  );
};

export default Taxes;

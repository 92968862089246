import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { Home } from "./home";
import Tokenomics from "./Tokenomics";
import Roadmap from "./Roadmap";

export const MainRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
    
      <Route path="/" element={<Home />} />
      <Route path="/tokenomics" element={<Tokenomics />} />
      <Route path="/roadmap" element={<Roadmap />} /> 
    </>
  )
);



import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import axios from 'axios'

const InputStyle = {
  bg: '#242526',
  outline: 'none',
  boxShadow: 'none',
  border: 'none'
}

const WaitListForm = ({ isOpen, onClose }) => {
  const [apiState, setApiState] = useState('idle')
  const initialRef = useRef(null)
  function validate (values) {
    const errors = {}
    if (!values.email) {
      errors.email = 'Email address is Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    if (!values.wallet) {
      errors.wallet = 'Wallet address is Required'
    }
    return errors
  }

  const joinWaitList = async values => {
    // console.log('values', values)
    try {
      setApiState('pending')
      await axios.post(
        'https://backend.hitmakr.antopolis.xyz/api/waitlist',
        values
      )
      setApiState('success')
      setTimeout(() => setApiState('idle'), 3000)
    } catch (ex) {
      console.log('Failed to add to waitlist:', ex)
      setApiState('error')
      setTimeout(() => setApiState('idle'), 3000)
    }
    onClose()
  }
  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bg='#131314'
        borderWidth={'thin'}
        borderColor={'#1f7964'}
        // bgGradient={
        //   'radial(240px 80px at 0% -0%, #1f7964, #226f5c, #26574a, #253c35, #212725, #1e1e1e)'
        // }
        color={'white'}
        m={2}
      >
        <ModalHeader>Join Waitlist</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{ email: '', wallet: '', twitter: '' }}
          validate={validate}
          onSubmit={(values, actions) => {
            joinWaitList(values)
          }}
        >
          {props => (
            <Form>
              <ModalBody>
                <Field name='email'>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <Input
                        {...InputStyle}
                        {...field}
                        placeholder='Email Address'
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='wallet'>
                  {({ field, form }) => (
                    <FormControl
                      mt={4}
                      isInvalid={form.errors.wallet && form.touched.wallet}
                    >
                      <Input
                        {...InputStyle}
                        {...field}
                        placeholder='Wallet Address'
                      />
                      <FormErrorMessage>{form.errors.wallet}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name='twitter'>
                  {({ field }) => (
                    <FormControl mt={4}>
                      <Input
                        {...InputStyle}
                        {...field}
                        placeholder='Twitter Handle'
                      />
                    </FormControl>
                  )}
                </Field>
              </ModalBody>
              <ModalFooter>
                <Button
                  w='full'
                  borderWidth={'thin'}
                  borderColor={'white'}
                  bg='white'
                  color='black'
                  _hover={{
                    bg: '#212224',
                    color: 'white',
                    borderColor: 'white',
                    boxShadow: '-7px 5px 0px 0px rgba(255,255,255, 1)',
                    transform: 'translate(4px, -4px)'
                  }}
                  isLoading={apiState === 'pending'}
                  type='submit'
                >
                  Join Waitlist
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default WaitListForm

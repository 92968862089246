import React from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import RoadmapSectionHeading from "./RoadmapSectionHeading";
import RoadmapContentItem from "./RoadmapContentItem";
import RoadmapPhase from "./RoadmapPhase";

const phase1Data = [
  "Finish Strategic Round of Investment for MVP",
  "Begin development of MVP",
  "Redesign Website , Solidify remaining Socials",
  "Secure Creative Partnerships / Onboard Artists",
  "Acquire Sponsors",
  "Build $HMKR token smart contract",
  "Partner with proof",
  "Get KYC’d by Assure",
  "HITMAKR Ad Campaign #1 Begins",
  "Creation of Hitmakr Media Company (HMC)",
  "Create Telegram",
  "Hitmakr Marketplace Development Begins",
  "Begin Twitter Spaces & AMAs",
];
const phase2Data = [
  "$HMKR Token Release",
  "$HMKR Dex Listings",
  "Begins token rewards",
  "Hitmakr Marketplace Development Finalizes",
  "Hitmakr App Infrastructure planning begins",
  "HITMAKR Ad Campaign #2 Begins",
  "HMC campaigns with Hitmakr Creative Partners",
  "Introduction of Hitmakr Node System to Validate Protocol",
  "Begin Collection of Audio Files & SFX for Factory",
];

const phase3Data = [
  "Social Media Marketing Intensifies",
  "$HMKR CEX Listings",
  "Hitmakr Marketplace Alpha Released",
  "Form More Partnerships with Major Labels to Onboard Several Artists @ Once",
  "Form More Partnerships with Producer Collectives",
  "Begin HMC Podcast",
  "Have Hitmakr Events",
  "Enable Paid Streaming on Hitmakr Platform",
];
function RoadmapContent({activeNavItem}) {
  return (
    <Box as="section" maxW="1300px" minH={"100vh"} px={".4rem"}>
      <RoadmapPhase
        phaseData={phase1Data}
        leftHeadingText="Phase 1"
        id="phase-1"
        rightHeadingText="Reaching MVP"
        isActive={activeNavItem === "phase-1"}
        pt={"1.5rem"}
      />
      <RoadmapPhase
        phaseData={phase2Data}
        leftHeadingText="Phase 2"
        id="phase-2"
        rightHeadingText="Growth"
        isActive={activeNavItem === "phase-2"}
        isLeft={1}
        pt={"10.5rem"}
      />
      <RoadmapPhase
        phaseData={phase3Data}
        leftHeadingText="Phase 3"
        id="phase-3"
        rightHeadingText="Scale"
        isActive={activeNavItem === "phase-3"}
        isEnd={true}
        pt={"10.5rem"}
      />
    </Box>
  );
}

export default RoadmapContent;

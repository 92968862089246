import { Box, Image, Spinner, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

/**
 *
 * @param {import('@chakra-ui/react').ImageProps} props
 * @returns
 */
export default (props, { children }) => {
  const [loaded, setIsLoaded] = useState(false)
  return (
    <Box pos={'relative'} {...props}>
      <Image
        {...props}
        onLoad={() => setIsLoaded(true)}
        opacity={loaded ? 1 : 0}
        loading='lazy'
      />
      {!loaded && (
        <VStack
          pos='absolute'
          h='full'
          w='full'
          // bg='#0000040'
          justify='center'
          align={'center'}
          top='0'
          left='0'
        >
          <Spinner color='white' />
        </VStack>
      )}
      {children}
    </Box>
  )
}

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React from "react";
import theme, { containerBreakpoints } from "../theme";
import Utility from "../components/tokenomicsComponents/Utility";
import TokenomicsSection from "../components/tokenomicsComponents/TokenomicsSection";
import Taxes from "../components/tokenomicsComponents/Taxes";
import TokenomicsSubscribe from "../components/tokenomicsComponents/TokenomicsSubscribe";
import TokenomicsFooter from "../components/tokenomicsComponents/TokenomicsFooter";
import Navbar from "../components/navbar/Navbar";

const Tokenomics = () => {
  return (
    <>
      <Navbar />
      <Box as="main" bg="darkColor.900" w="100%" minH="100vh" pt="7rem">
        <Container maxW={containerBreakpoints} color="white" mb={"5.5rem"}>
          <SimpleGrid
            columns={4}
            spacing={20}
            minChildWidth={{ base: "100%", md: "450px" }}
            fontFamily="poppins"
          >
            <Box>
              <Heading as="h2" color="white" mb="1rem">
                Tokenomics
              </Heading>
              <Text mb=".6rem">
                $HMKR Tokenomics are designed to give holders rewards for
                helping in our vision of changing the way we all see audio.
                $HMKR isn’t just a token, it’s the community’s response to the
                suboptimal music experience now and puts the evolution of music
                into the hands of artists and consumers.
              </Text>
              <Text>
                It starts with the token, but will soon be followed by the
                marketplace and more. These different forms of income will all
                systematically back the token through buybacks and burns.
              </Text>
            </Box>
            <Box pt="4rem">
              <Text
                fontWeight="500"
                fontSize="1.5rem"
                lineHeight="1.5rem"
                mb=".5rem"
              >
                Total Supply
              </Text>
              <Text
                fontWeight="500"
                fontSize="2.8rem"
                lineHeight="2.8rem"
                mb="1.5rem"
                color="green.900"
                bgGradient="linear(to-r, green.800, green.900)"
                bgClip="text"
                display="inline-block"
              >
                300M
              </Text>
              <Flex gap=".8rem" flexWrap="wrap" w={"100%"}>
                <Text
                  bg="darkColor.800"
                  p=".6rem"
                  borderRadius="12px"
                  flexGrow={1}
                  fontSize={{
                    base: ".8rem",
                  }}
                  w={{ base: "100%", md: "70%" }}
                  wordBreak={"break-word"}
                >
                  0x3300b02efa180c99a2F61f4731665b51e4E254C4
                </Text>
                <Button
                  bg="green.900"
                  color="#fff"
                  w={{ base: "fit-content", md: "25%" }}
                >
                  Dextools
                </Button>
              </Flex>
            </Box>
          </SimpleGrid>
        </Container>
        {/* curstom components */}
        <Utility />
        <TokenomicsSection />
        <Taxes />
        <TokenomicsSubscribe />
        <TokenomicsFooter />
      </Box>
    </>
  );
};

export default Tokenomics;

import React from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
function RoadmapContentItem({
  isLeft = true,
  Text,
  isLastChild = false,
  isEnd = false,
}) {
  return (
    <Grid
      justifyContent={"center"}
      templateColumns="repeat(10, 1fr)"
      gap={".7rem"}
      minH={"100px"}
    >
      <GridItem
        as={"h2"}
        colSpan={"4"}
        fontSize={{ base: ".8rem", md: "1.2rem" }}
        lineHeight={{ base: "1.5rem", md: "1.8rem" }}
        fontWeight={"400"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={!isLeft ? "flex-start" : "flex-end"}
        textAlign={!isLeft ? "left" : "right"}
      >
        {isLeft ? Text : ""}
      </GridItem>

      <GridItem
        colSpan={"2"}
        _before={{
          w: { base: ".5rem", md: ".7rem" },
          h: { base: ".5rem", md: ".7rem" },
          borderRadius: "50%",
          bg: "#fff",
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50.5%",
          translate: "-50% -50%",
          zIndex: "1",
        }}
        _after={{
          w: "50%",
          h:
            isLastChild && !isEnd
              ? { base: "372%", md: "220%" }
              : isLastChild && isEnd
              ? "2px"
              : "100%",
          borderTop: "2px solid rgba(255, 255, 255, 0.15)",
          borderRight: isLeft ? "2px solid rgba(255, 255, 255, 0.15)" : "none",
          borderLeft: isLeft ? "none" : "2px solid rgba(255, 255, 255, 0.15)",
          content: '""',
          position: "absolute",
          top: "50%",
          left: isLeft ? "0%" : "50%",
          translateY: "-50%",
        }}
        position={"relative"}
      ></GridItem>
      <GridItem
        as={"h2"}
        colSpan={"4"}
        fontSize={{ base: ".8rem", md: "1.2rem" }}
        lineHeight={{ base: "1.5rem", md: "1.8rem" }}
        fontWeight={"400"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={!isLeft ? "flex-start" : "flex-end"}
        textAlign={!isLeft ? "left" : "right"}
      >
        {isLeft ? "" : Text}
      </GridItem>
    </Grid>
  );
}

export default RoadmapContentItem;

import { Image as ChakraImage, VStack } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
/**
 *
 * @param {import('@chakra-ui/react').StackProps} props
 * @returns
 */
export default props => {
  const startYPos = useRef(0)
  const endYPos = useRef(0)

  const oldScroll = useRef(0)
  const imageRef = useRef()
  const containerRef = useRef()
  const [imageLoaded, setImagesLoaded] = useState(false)
  const ImageArray = useRef([])

  useEffect(() => {
    startYPos.current = containerRef.current?.getBoundingClientRect().top / 2
    endYPos.current = startYPos.current + 94
  }, [])

  useEffect(() => {
    if (!imageLoaded) {
      const preloadImages = async () => {
        console.log('Image load started')
        try {
          const imagePromises = Array(93)
            .fill(null)
            .map((_, index) => {
              const image = new Image()
              image.src = require(`../assets/video_frames/${index}.jpg`)
              return new Promise((resolve, reject) => {
                image.onload = () => {
                  ImageArray.current.push(
                    require(`../assets/video_frames/${index}.jpg`)
                  )
                  resolve()
                }
                image.onerror = reject
              })
            })
          await Promise.all(imagePromises)
          ImageArray.current = [...new Set(ImageArray.current)]
          ImageArray.current.sort((a, b) => {
            const numberA = parseInt(a.split('/media/')[1].split('.')[0])
            const numberB = parseInt(b.split('/media/')[1].split('.')[0])

            // Compare the numbers
            return numberA - numberB
          })
          console.log('Image load complete', ImageArray.current.length)
          // console.log('Image array:', ImageArray)
          setImagesLoaded(true)
        } catch (error) {
          console.error('Error preloading images:', error)
        }
      }
      preloadImages()
    }

    if (containerRef.current && imageRef.current) {
      imageRef.current.src = ImageArray.current[0]
      const parent = document.getElementById('parent')
      let currentImageIndex = 0

      const handleScroll = () => {
        var st = parent.scrollTop
        console.log('Scrolling...')
        if (st > oldScroll.current) {
          console.log('Scrolling down...')
          //   Scrolling down
          if (st > startYPos.current) {
            if (currentImageIndex < 92) {
              currentImageIndex += 1
            }
            imageRef.current.src = ImageArray.current[currentImageIndex]
            console.log('Setting image src:', imageRef.current.src)
          }
        } else if (st < oldScroll.current) {
          //   Scrolling up
          if (st > startYPos.current) {
            if (currentImageIndex > 0) {
              currentImageIndex -= 1
            }
            imageRef.current.src = ImageArray.current[currentImageIndex]
          }
          // console.log('Scrolling up:', st)
        }
        // console.log('Current index:', ImageArray.current[currentImageIndex])
        oldScroll.current = st
      }
      console.log('Attaching scroll event...')
      parent.addEventListener('scroll', handleScroll)
    }
  }, [imageLoaded, imageRef.current, containerRef.current])

  return (
    <VStack ref={containerRef} w='full' {...props}>
      {imageLoaded && (
        <ChakraImage
          ref={imageRef}
          h='full'
          w='full'
          src={''}
          objectFit={{ md: 'cover', base: 'cover' }}
        />
      )}
    </VStack>
  )
}

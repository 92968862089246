import React, { useEffect, useState } from "react";
import { Box, Heading, Text, List, ListItem, Link } from "@chakra-ui/react";
function RoadmapNavArea({ activeNavItem, setActiveNavItem }) {
  const [isLogoHide, setIsLogoHide] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const scrollToSection = (elementId) => {
    const element = document.getElementById(elementId);
    element.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const root = document.getElementById("parent");

    const trackScreen = () => {
      const screen = window.innerWidth;
      if (screen < 768) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }

      console.log(isMobileScreen, screen);
    };

    trackScreen();

    const handleScroll = (e) => {
      const scrolling = window.scrollY;
      console.log(scrolling)
      if (scrolling > 10 && isMobileScreen) {
        setIsLogoHide(true);
      } else {
        setIsLogoHide(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", trackScreen);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", trackScreen);
    };
  }, [isMobileScreen]);
  return (
    <Box
      as={"sidebar"}
      minW={{ base: "100%", md: "300px" }}
      height={{ base: "fit-content", md: "100vh" }}
      colSpan={"2"}
      overflow={{ base: "initial", md: "auto" }}
      position={"fixed"}
      top={{base: "6rem", md: "13rem"}}
      left={"0"}
      display={"flex"}
      flexDir={"column"}
      gap={"2rem"}
      zIndex={"999"}
      bg={"darkColor.900"}
    >
      <Heading
        order={{ base: "2", md: "1" }}
        pl={"4rem"}
        display={isLogoHide ? "none" : "block"}
      >
        <Text
          fontWeight={"400"}
          fontSize={{ base: ".9rem", md: "1.1rem" }}
          mb={".6rem"}
          lineHeight={"1.1rem"}
        >
          Our Legacy
        </Text>
        <Text
          fontWeight={"500"}
          fontSize={{ base: "2.2rem", md: "3rem" }}
          lineHeight={{ base: "2.2rem", md: "3rem" }}
          position={"relative"}
        >
          <Box
            position={"absolute"}
            top={"50%"}
            right={"calc(100% + .5rem)"}
            translateY={"-50%"}
            w={"3.5rem"}
            h={{ base: "2px", md: "4px" }}
            bg={"#fff"}
          ></Box>
          Roadmap
        </Text>
      </Heading>

      <List
        display={"flex"}
        flexDir={{ base: "row", md: "column" }}
        order={{ base: "1", md: "2" }}
        gap={".8rem"}
        mt={{ base: 0, md: "10rem" }}
        pl={{ base: "0", md: "4rem" }}
        justifyContent={{ base: "center", md: "start" }}
      >
        <ListItem>
          <Link
            href="#phase-1"
            fontWeight={"500"}
            fontSize={{ base: ".9rem", md: "2rem" }}
            lineHeight={{ base: ".9rem", md: "2rem" }}
            color={
              activeNavItem === "phase-1" ? "#fff" : "rgba(255, 255, 255, 0.25)"
            }
            border={
              activeNavItem === "phase-1"
                ? { base: "1px solid rgba(255, 255, 255, 0.25)", md: "none" }
                : "none"
            }
            bg={
              activeNavItem === "phase-1"
                ? { base: "darkColor.600", md: "transparent" }
                : "transparent"
            }
            px={{ base: "1rem", md: "0rem" }}
            py={{ base: ".2rem", md: "0rem" }}
            borderRadius={"50px"}
            onClick={() => {
              setActiveNavItem("phase-1");
            }}
          >
            Phase 1
          </Link>
        </ListItem>
        <ListItem>
          <Link
            href="#phase-2"
            fontWeight={"500"}
            fontSize={{ base: ".9rem", md: "2rem" }}
            lineHeight={{ base: ".9rem", md: "2rem" }}
            color={
              activeNavItem === "phase-2" ? "#fff" : "rgba(255, 255, 255, 0.25)"
            }
            border={
              activeNavItem === "phase-2"
                ? { base: "1px solid rgba(255, 255, 255, 0.25)", md: "none" }
                : "none"
            }
            bg={
              activeNavItem === "phase-2"
                ? { base: "darkColor.600", md: "transparent" }
                : "transparent"
            }
            px={{ base: "1rem", md: "0rem" }}
            py={{ base: ".2rem", md: "0rem" }}
            borderRadius={"50px"}
            onClick={() => {
              setActiveNavItem("phase-2");
            }}
          >
            Phase 2
          </Link>
        </ListItem>
        <ListItem>
          <Link
            href="#phase-3"
            fontWeight={"500"}
            fontSize={{ base: ".9rem", md: "2rem" }}
            lineHeight={{ base: ".9rem", md: "2rem" }}
            color={
              activeNavItem === "phase-3" ? "#fff" : "rgba(255, 255, 255, 0.25)"
            }
            border={
              activeNavItem === "phase-3"
                ? { base: "1px solid rgba(255, 255, 255, 0.25)", md: "none" }
                : "none"
            }
            bg={
              activeNavItem === "phase-3"
                ? { base: "darkColor.600", md: "transparent" }
                : "transparent"
            }
            px={{ base: "1rem", md: "0rem" }}
            py={{ base: ".2rem", md: "0rem" }}
            borderRadius={"50px"}
            onClick={() => {
              setActiveNavItem("phase-3");
            }}
          >
            Phase 3
          </Link>
        </ListItem>
      </List>
    </Box>
  );
}

export default RoadmapNavArea;

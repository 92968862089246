import React from "react";
import { containerBreakpoints } from "../../theme";
import {
  Box,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

const utilityData = [
  {
    title: "Supreme Networking",
    description: `Vault created for the specific purpose of helping creatives connect with relevant and essential figures in high interest fields.  Hitmakr will provide many opportunities for creatives to gain exposure and express themselves in new ways.`,
    icon: "/utility/database.png",
  },
  {
    title: "Supreme Networking",
    description: `Vault created for the specific purpose of helping creatives connect with relevant and essential figures in high interest fields.  Hitmakr will provide many opportunities for creatives to gain exposure and express themselves in new ways.`,
    icon: "/utility/cpu.png",
  },
  {
    title: "Supreme Networking",
    description: `Vault created for the specific purpose of helping creatives connect with relevant and essential figures in high interest fields.  Hitmakr will provide many opportunities for creatives to gain exposure and express themselves in new ways.`,
    icon: "/utility/code.png",
  },
  {
    title: "Supreme Networking",
    description: `Vault created for the specific purpose of helping creatives connect with relevant and essential figures in high interest fields.  Hitmakr will provide many opportunities for creatives to gain exposure and express themselves in new ways.`,
    icon: "/utility/award.png",
  },
];

const Utility = () => {
  return (
    <Container maxW={containerBreakpoints} color="white" mb={"5.5rem"}>
      <Center mb="4.8rem">
        <Heading as="h2" color="white" fontSize="2.7rem" lineHeight={"1.7rem"}>
          Utility
        </Heading>
      </Center>
      <Grid
        templateColumns="repeat(6, 1fr)"
        spacing={20}
        fontFamily="poppins"
        gap={"1.5rem"}
      >
        {utilityData.map((item, index) => (
          <GridItem
            key={index}
            bg={{ base: "darkColor.600", md: "darkColor.800" }}
            borderRadius="12px"
            p={"1rem"}
            pt={"2.5rem"}
            colSpan={
              index == utilityData.length - 1
                ? { base: 6, md: 3 }
                : { base: 6, md: 2 }
            }
            order={index == utilityData.length - 1 ? index + 2 : index + 1}
          >
            <Image
              src={item.icon}
              mb="1rem"
              border={"1px solid rgba(255, 255, 255, 0.25)"}
              p={".4rem"}
              borderRadius={"6px"}
            />
            <Heading
              as="h3"
              color="white"
              mb="1rem"
              fontSize="1.7rem"
              lineHeight={"1.7rem"}
            >
              {item.title}
            </Heading>
            <Text mb=".6rem" fontSize="1.1rem" lineHeight="27px">
              {item.description}
            </Text>
          </GridItem>
        ))}
        <GridItem
          bg={{ base: "darkColor.600", md: "darkColor.800" }}
          borderRadius="12px"
          p={"1rem"}
          colSpan={{ base: 6, md: 3 }}
          order={4}
          pt={"2.5rem"}
        >
          <Flex
            justifyContent={"space-between"}
            gap={"1.8rem"}
            alignItems={"center"}
            flexDir={{ base: "column", lg: "row" }}
          >
            <SimpleGrid
              order={{ base: 2, lg: 1 }}
              columns={{ base: 2, md: 1 }}
              justifyContent={"space-between"}
              columnGap={{ base: "10%", md: "0" }}
              gap={{ base: "1rem", md: "0" }}
              w={"100%"}
            >
              <Flex
                as={"p"}
                mb=".6rem"
                fontSize="1.1rem"
                lineHeight="27px"
                alignItems={"center"}
                gap={".7rem"}
              >
                <Box as="span" w={".9rem"} h={".9rem"} bg={"green.900"}></Box>
                Liquidity (70%)
              </Flex>

              <Flex
                as={"p"}
                mb=".6rem"
                fontSize="1.1rem"
                lineHeight="27px"
                alignItems={"center"}
                gap={".7rem"}
              >
                <Box as="span" w={".9rem"} h={".9rem"} bg={"green.800"}></Box>
                Marketing (10%)
              </Flex>

              <Flex
                as={"p"}
                mb=".6rem"
                fontSize="1.1rem"
                lineHeight="27px"
                alignItems={"center"}
                gap={".7rem"}
              >
                <Box as="span" w={".9rem"} h={".9rem"} bg={"green.700"}></Box>
                VC’s (5%)
              </Flex>

              <Flex
                as={"p"}
                mb=".6rem"
                fontSize="1.1rem"
                lineHeight="27px"
                alignItems={"center"}
                gap={".7rem"}
              >
                <Box as="span" w={".9rem"} h={".9rem"} bg={"green.600"}></Box>
                Exchanges (5%)
              </Flex>

              <Flex
                as={"p"}
                mb=".6rem"
                fontSize="1.1rem"
                lineHeight="27px"
                alignItems={"center"}
                gap={".7rem"}
              >
                <Box as="span" w={".9rem"} h={".9rem"} bg={"green.500"}></Box>
                Team (6%)
              </Flex>

              <Flex
                as={"p"}
                mb=".6rem"
                fontSize="1.1rem"
                lineHeight="27px"
                alignItems={"center"}
                gap={".7rem"}
              >
                <Box as="span" w={".9rem"} h={".9rem"} bg={"green.400"}></Box>
                Partners (2%)
              </Flex>

              <Flex
                as={"p"}
                mb=".6rem"
                fontSize="1.1rem"
                lineHeight="27px"
                alignItems={"center"}
                gap={".7rem"}
              >
                <Box as="span" w={".9rem"} h={".9rem"} bg={"white"}></Box>
                Vault (2%)
              </Flex>
            </SimpleGrid>

            <Image
              src="/utility/chart.png"
              order={{ base: 1, lg: 2 }}
              maxW={"284px"}
            />
          </Flex>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default Utility;

import React from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
function RoadmapSectionHeading({ leftText, rightText, isActive = false, pt}) {
  return (
    <Grid
      justifyContent={"center"}
      alignItems={"center"}
      templateColumns="repeat(10, 1fr)"
      gap={".7rem"}
      pt={{ base: pt, md: "30px" }}
      pb={{ base: "5.5rem", md: "30px" }}
      color={isActive ? "#fff" : "darkColor.600"}
      className="phase"
    >
      <GridItem
        as={"h2"}
        colSpan={"4"}
        textAlign={"right"}
        fontSize={{ base: "1.2rem", md: "3.8rem" }}
        lineHeight={{ base: "1.2rem", md: "3.8rem" }}
        fontWeight={"600"}
      >
        {leftText}
      </GridItem>

      <GridItem
        colSpan={"2"}
        _before={{
          w: { base: ".5rem", md: "1rem" },
          h: { base: ".5rem", md: "1rem" },
          borderRadius: "50%",
          bg: isActive ? "#fff" : "darkColor.600",
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50.5%",
          translate: "-50% -50%",
          outline: isActive ? `1px solid #fff` : "none",
          outlineOffset: { base: ".3rem", md: ".5rem" },
          boxShadow: isActive
            ? {
                base: "0px 0px 0px 4.5px #020202",
                md: "0px 0px 0px 9px #020202",
              }
            : "none",
          zIndex: "1",
        }}
        _after={{
          w: "100%",
          h: "2px",
          bg: "darkColor.600",
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          translate: "-50% -50%",
        }}
        position={"relative"}
      ></GridItem>
      <GridItem
        as={"h2"}
        colSpan={"4"}
        textAlign={"left"}
        fontSize={{ base: "1.2rem", md: "3.8rem" }}
        lineHeight={{ base: "1.2rem", md: "3.8rem" }}
        fontWeight={"600"}
      >
        {rightText}
      </GridItem>
    </Grid>
  );
}

export default RoadmapSectionHeading;

import { Avatar, AvatarGroup, Box, Container, Text } from "@chakra-ui/react";
import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { containerBreakpoints } from "../../theme";
import { FaTwitter } from "react-icons/fa6";
import { FaInstagram, FaTelegram } from "react-icons/fa";
const TokenomicsFooter = () => {
  return (
    <Box
      as="footer"
      w="100%"
      borderTop={"1px solid rgba(255, 255, 255, 0.25)"}
      color={"white"}
      py={"2rem"}
    >
      <Container
        as={"footer"}
        maxW={containerBreakpoints}
        display={"flex"}
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems={"center"}
        flexWrap={"wrap"}
        gap={"1.8rem"}
      >
        <Text as={"p"} textAlign={{ base: "center", md: "left" }}>
          Copyright © 2023. All rights reserved{" "}
          <Text as={"span"} color={"green.900"} fontWeight={"700"}>
            HITMAKR
          </Text>
        </Text>
        <AvatarGroup spacing="1rem">
          <Avatar
            bg="green.900"
            icon={<FaTwitter fontSize="1.5rem" />}
            border={"none"}
          />
          <Avatar
            bg="green.900"
            icon={<FaTelegram fontSize="1.5rem" />}
            border={"none"}
          />
          <Avatar
            bg="green.900"
            icon={<FaInstagram fontSize="1.5rem" />}
            border={"none"}
          />
        </AvatarGroup>
      </Container>
    </Box>
  );
};

export default TokenomicsFooter;

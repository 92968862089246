import React from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import RoadmapSectionHeading from "./RoadmapSectionHeading";
import RoadmapContentItem from "./RoadmapContentItem";
function RoadmapPhase({
  phaseData,
  leftHeadingText,
  rightHeadingText,
  isActive,
  isLeft = 0,
  isEnd,
  id,
  pt
}) {
  return (
    <Box as="section"  id={id}>
      <RoadmapSectionHeading
        leftText={leftHeadingText}
        rightText={rightHeadingText}
        isActive={isActive}
        pt={pt}
      />
      {phaseData.map((text, index) => (
        <RoadmapContentItem
          isLeft={index % 2 === isLeft}
          Text={text}
          isLastChild={phaseData.length - 1 === index}
          isEnd={isEnd}
        />
      ))}
    </Box>
  );
}

export default RoadmapPhase;

import React, { useEffect, useState } from "react";
import { GridItem, Grid } from "@chakra-ui/react";
import { containerBreakpoints } from "../theme";
import RoadmapNavArea from "../components/roadmapSectionComponent/RoadmapNavArea";
import RoadmapContent from "../components/roadmapSectionComponent/RoadmapContent";
import Navbar from "../components/navbar/Navbar";

const Roadmap = () => {
  const [activeNavItem, setActiveNavItem] = useState("phase-1");
  useEffect(() => {
    const phases = document.querySelectorAll(".phase");
    const observer = new IntersectionObserver((observedElem) => {
      observedElem.forEach((elem) => {
        console.log(elem.target.id);
        if(elem.isIntersecting){
          setActiveNavItem(elem.target.parentElement.id);
        }
      });
    });

    phases.forEach((item) => {
      observer.observe(item, {threshold: 1});
    });
  }, [setActiveNavItem]);
  return (
    <>
      <Navbar />
    <Grid
      as="main"
      bg="darkColor.900"
      w="100%"
      minH="100vh"
      pt="7rem"
      color={"#fff"}
      templateColumns="repeat(12, 1fr)"
      fontFamily="poppins"
      pb={"12rem"}
    >
      {/* Roadmap Navigation Area */}
      <RoadmapNavArea
        activeNavItem={activeNavItem}
        setActiveNavItem={setActiveNavItem}
      />
      <GridItem colSpan={{ base: "12", md: "3" }}></GridItem>
      <GridItem
        colSpan={{ base: "12", md: "9" }}
        mt={{ base: "9rem", md: 0 }}
        pt={{ base: "0rem", md: "6rem" }}
      >
        <RoadmapContent activeNavItem={activeNavItem} />
      </GridItem>
    </Grid>
    </>
  );
};

export default Roadmap;

import React from "react";
import { containerBreakpoints } from "../../theme";
import {
  Box,
  Center,
  Container,
  Grid,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

const tokenomicsData = [
  {
    title: "Liquidity",
    description: `Majority of the funds will go towards Liquidity to ensure a healthy LP for the token. Early backers will be rewarded in 2 ways:
        Whitelist sale with discounted tokens vs. public launch price
        Tokens not sold during the public phase will be burnt, amplifying the value of all remaining tokens.`,
    percentage: "70%",
  },
  {
    title: "Marketing",
    description: `A 10% marketing budget has been set to allow the team to focus on creating ad campaigns and working with influential people who can accelerate the adoption of the platform.`,
    percentage: "10%",
  },
  {
    title: "VC’S",
    description: `There will be an amount of tokens set aside for Venture Capitalists. These tokens will be used to expedite the MVP process, and optimize the amount of time it will take to have a fully functioning MVP out to the world. Any VC tokens left by the release of the MVP will be burned to amplify the value of existing tokens.`,
    percentage: "5%",
  },
  {
    title: "Exchanges",
    description: `There will be a set amount put aside for Central Exchanges. This will give $HMKR more exposure and accelerate adoption.`,
    percentage: "5%",
  },
  {
    title: "Team",
    description: `Tokens set aside for the team will feature a cliff and vesting periods for each team member.`,
    percentage: "6%",
  },
  {
    title: "Partners",
    description: `We will allocate a certain amount of tokens for strategic partnerships and advisory.`,
    percentage: "2%",
  },
  {
    title: "Vault",
    description: `There will be a small portion set aside for operations and other miscellaneous expenses.`,
    percentage: "2%",
  },
];

const TokenomicsSection = () => {
  return (
    <Container maxW={containerBreakpoints} color="white" mb={"5.5rem"}>
      {/* Heading area */}
      <Center mb="4.8rem">
        <Heading as="h2" color="white" fontSize="2.7rem" lineHeight={"1.7rem"}>
          Tokenomics
        </Heading>
      </Center>
      {/* content area */}
      <Grid
        templateColumns="repeat(12, 1fr)"
        spacing={20}
        fontFamily="poppins"
        gap={"1.5rem"}
      >
        {tokenomicsData.map((item, index) => (
          <GridItem
            key={index}
            bg={{base: "darkColor.600", md: "darkColor.800"}}
            borderRadius="12px"
            p={"1rem"}
            pt={"2.5rem"}
            colSpan={index < 3 ? { base: 12, md: 4 } : { base: 12, md: 3 }}
          >
            <Heading
              as="h3"
              color="white"
              mb="1rem"
              fontSize="1.2rem"
              lineHeight={"1.2rem"}
            >
              {item.title}
            </Heading>
            <Text
              fontWeight="500"
              fontSize={{base: "3.2rem", md:"4rem"}}
              lineHeight="4.8rem"
              mb="1.5rem"
              display="inline-block"
            >
              {item.percentage}
            </Text>
            <Text mb=".6rem" fontSize="1.1rem" lineHeight="27px">
              {item.description}
            </Text>
          </GridItem>
        ))}
      </Grid>
    </Container>
  );
};

export default TokenomicsSection;

import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
  VStack,
  useMediaQuery
} from '@chakra-ui/react'
import { HiArrowRight } from 'react-icons/hi'
import {
  FaTiktok,
  FaMusic,
  FaUserTie,
  FaMicrophoneAlt,
  FaGuitar,
  FaHandshake,
  FaTwitter,
  FaTelegram,
  FaInstagram,
  FaUsers,
  FaCheck
} from 'react-icons/fa'
import { MdOutlineOndemandVideo } from 'react-icons/md'
import { CustomTabs } from '../components/customTab'
import { FaRegIdBadge } from 'react-icons/fa6'
import { GiReceiveMoney } from 'react-icons/gi'
import { IoStatsChartOutline } from 'react-icons/io5'
import { BsFiletypeMp3 } from 'react-icons/bs'
import AssureLogo from '../assets/assure_defi_trans.png'
import ProofLogo from '../assets/proof_trans.png'
import SkaleLogo from '../assets/skale_trans.png'
import WaveIcon from '../assets/swirl.png'
import ScrollAnimatedFrame from '../components/scroll_animated_frame'
import LazyImage from '../components/lazyImage'
import { IoMdSkipForward, IoMdSkipBackward, IoMdPlay } from 'react-icons/io'
import { useState } from 'react'
import WaitListForm, { joinWaitList } from '../components/WaitlistForm'
import VinylImage from '../assets/vinyl.png'
import './home.css'
import axios from 'axios'
import Navbar from '../components/navbar/Navbar'

const AlbumImages = [
  'https://s3-alpha-sig.figma.com/img/23f7/5c06/accc0f7a5d833e2bb5272ca848789e3e?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YlHenY90frvZNMwU8XPJByfHK6W1yYi~DZmVBSXR0acV4JGoEiXDUxoxnLpl3GesqWlSWnoCKeQgzoIinF8chWN1oGXlUokJBgDau7CESSpN8b7v4ka7tzdItlmUZxqqsOmi~7Sk0i~lwPBreC0K-uN7nS7R5Dj8PRgvhjlI~X8P0F8t8qWJWlFrFJjc3XHNuOuN4qnLaBijfhDTqzGmWnkNtgvyy1GBNj67LJUxqHUZT1lLczJHGftBP1DvQ-gXDoRE2OORpOmfeA7hJlnqV9x3l82t7-LsmGXyhlhORWSqsstLBCd-DnfGrvE44a1BglDxO2704wcDdknBI65-sA__',
  'https://s3-alpha-sig.figma.com/img/081f/447b/477a931cda841a0fbff5c2279ddbf176?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=m668nSQcKjlz0CG~BC1BeK4jjmX-udFOGGq1xOUjYkpNF4Tf9Mjnp2pXGc1qCJyki9w2d3b~WfjiXzqgBZw8IyIYUkgkq1OIXOc2-7kWlAwLhk7lrgtRTDyjtQ5YZz5Y7uYgyWxA5P0pDH7CArmmxMDjXmVqGFl4hvGIYlGqE5CdX~yFvhj3XwjNyH-8rplZ5Bc8F8AEvBMGP0J7tEK8gyQEjQ9pjFmLdesPBlrvcEBR8cIlORvg2tpBH10PF6Um5UjbL1b6W5s3V2AM3jlXonk31YXPF0tmEsyTArPYyRANgBLSlQfTIW3xsalvl~QKK9gPw5DTzgmqfy~OfwVrKQ__',
  'https://s3-alpha-sig.figma.com/img/b614/545a/182a162ff744c87a398f2f64c2f32b00?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TSqsV8FchvCQDewFmEvI3ymBshz0r6uy5qaZAjH4u~eDT48oWR4q6uYB~xBH1pu74Jkwq9GofA97ONrQfVjURkYdsx4YBLvxHQWzXZyLqwlD1Nf-HljtPjQCqJJ6RssoWdDmaqOTCpizEUf7Tdeg1GkJ8qCN6pimzwytg3v1A4FkXDEqagF5VXHVqrfs46hRo0dRtP~o1e5MAdJyglHkujT2aDpmJONXdaNfwLc-RZDOwxMxGBgKQixT4ozqZcSCm3l4PM8ewZoJw2vrZyJa3CLucY8tz-g99i4nG7mtCyu66x7mA7X7ECXmWVVTyL3DtYaE1hL3LMq0bgFj57-SRw__',
  'https://s3-alpha-sig.figma.com/img/d3bc/aa17/74a03d00116676f325ff9f18ec43284b?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SJKAtgKclLzkJ5OaVF7jMAVGHs2y6AyreRhwrr8YURMEadE0YrBo9czK7qeSgmsk0ei45ghPl26GVJTBfjR9UBuMmmK9dwJoTaHrWKMTtqBqFaF4bnNCkycZx0tqOTbkrcWrEcUkxlgUTQrgZIcuu2d3eED58FvOB9bCjANvXvbe5qDVRQbQyRq~8Yyw380G0ruWZk~j9EpEZuVMgPy326pYHy-OrN-7jo9yJtsY6dJORNjGwmKcTZRIgGC0Pseg0v2279BhzbI~I76zkUd34eGsWjtYBCoMubohnfxb9H00Lzkr6~fSbWdigzW-iL1GwXwAoua4mJc4je2Devxy8w__',
  'https://s3-alpha-sig.figma.com/img/4c32/78db/560ed9bfac00dc0284fa2615a2f41d13?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eeK4vj4YT4mweeu~--n05iSLY8xVrPzitgV~H-nf2~9Z2GGb6peB5qGlFfGZuDRpZdEBFHF4NAMEWssnzAsiyRya9huR9Ci4ianNtarM6JQJ1JVOJ~prnQqfuc3MpCUM5wKHu33ixeN5sQBqtcOyznPeG70txoKsqoPt6VzznKBKLK7kt-qjc7-Q~KPk4UUpGxZigLfdywx-m83AbzCrSOQjyJitcW~jhBhe1CudIbxobnL7Jwuz4PMy-Xmf3kfNLmCO44Kdusf94f6ZvWAtC35JzKhAIGq5G6B8of72wNr6xvC6mf1CCWWwAkjCFpGKU---WvG0RRNl2TMAyOjbLw__',
  'https://s3-alpha-sig.figma.com/img/5865/6bb7/c0ad37b172799708d9d1e77dcae4f056?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=UGfUoTJtJMWOOGJo2W4fo44AB3MTyzcaWpneM9FzW7BKFWmgA4DpkU1WnNqhstJlu3H9rlp6G4wcCFZxbY-18lvorTmClB5AhIKpKz8AYTcGj7u3EKCmo84HAeygTkf4K~HY6XCSVDjZSyFa1GgvfVy3Ma~GSGI~~h4IKNKSGglhLbSikw8VHYvkShbzl93CyzxEGdCgOtQ9Jbfv7G03GAfrl~zA4JbKtARrizlinLLoR3RJ8oo4AHmFX1lSHxT96nzWDZfnQyoF~~e-msafVebylZwbpkI2-W0n5z6OOPXtrQLwYOC0Kui0HZ7u0DpGnb88SybQ6GiWS6kIxUeh~g__',
  'https://s3-alpha-sig.figma.com/img/9391/b123/6e245a15abd0f583425512842fa9695c?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=HzfosRfs3YsSKKQMfRXh9mzWvvBhE6QTIw9oANzYFTIMBjvg-t8VxsJlU~bsku0lo19fkR6GsdeUgUAOgOCRnyqH5PqN~6YjxnbiWZ894gs67TDROoQ6inRVcA~t9NiJoDMzwnM8cDVYoDW3m6f7VcNtDINQAHmli67weFzJv2N10EzNMUTKNMMXdt9aAj77CXCAaQkYLDC6v8aFquyIpOPZvjLP01akWrYhNN5t64NfQUhwLMydV4-zSwyAPfo~ODsl5~hTlepb-72UG0faF34PaqziJsK2KsKKZ5WHdCsWUHRGAb1nEB6RVNfeTQ9MnIJycy7zq3~W1Fvw8T7UcQ__',
  'https://s3-alpha-sig.figma.com/img/8ccf/bbd4/3b300deca6b9e5b85830c2e5163bb252?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=n5obrvEqVklu3tVW4K9wITFkFcxP4FIyrqokBwP1R2-tRZ1nSRye7O8K6wyI8JPmVsCyqXgDnIpUwUOuGvhjQuDmxYsY~a2oZawmRxSuaDpzCEQSwbKLvsq5qavm-J-OSI5uKQVM6YncQJVlpWowl~pALN6-rNKuIo9Mp9~Tmxfz7gBhiIMLs4cmjsPNU8baqeJqVJiA9S-1c8m~PpKGGB8Hf2LOZdw4KZeIDioxL3-6nz7rT-kYF0MbvKyGNg6hA7oPsJgkoHg9iPSlHytpZaWS4fkb4EPVXmN3KOoSHjSHwjd82fSrX7Sl2ltu8zEJNHrVesm0itRfL~6UQuhcwA__'
]

const ImageSlider = ({ style, nthChild = 1 }) => {
  return (
    <HStack w='max-content' h='max-content' {...style} spacing={'3'}>
      {AlbumImages.slice(0, 4 * nthChild).map(src => (
        <LazyImage
          h={{ md: '220px', base: '120px' }}
          w={{ md: '320px', base: '200px' }}
          bg='#2902a120'
          borderRadius='md'
          src={src}
          transform='scaleX(1)'
          transition={'all 300ms'}
          objectFit={'cover'}
          _hover={{
            transform: 'scale(1.02) translate(0px, -5px)',
            opacity: '1'
          }}
        />
      ))}
    </HStack>
  )
}

const HeroSection = ({ onRequestModal }) => {
  const [isMobile] = useMediaQuery('(max-width: 1000px)')

  function goToDextools () {
    window
      .open(
        'https://www.dextools.io/app/en/ether/pair-explorer/0x0CDC1ec839E8B3FCfa9f726d9C4732417cBD2D58?t=1712347121307',
        '_blank'
      )
      .focus()
  }
  const MobileVersion = (
    <>
      <Navbar />
      <VStack
        justify={'center'}
        h='max-content'
        //   py='1vh'
        color='white'
        w='full'
        px='15'
        py='20'
        textAlign='center'
        alignSelf={'center'}
        bgGradient='linear(to-tr, #5a419f, #289d84)'
        pos='relative'
      >
        <Button
          pos='absolute'
          colorScheme='ghost'
          bg='white'
          color='#7A52EE'
          boxShadow={'0px 2px 11px 3px rgba(0,0,0,0.15)'}
          transition={'all 300ms'}
          _hover={{
            bg: '#212224',
            color: 'white',
            borderColor: 'transparent',
            boxShadow: '-7px 5px 0px 0px rgba(255,255,255,0.75)',
            transform: 'translate(4px, -4px)'
          }}
          role='group'
          size='sm'
          top='5'
          right={'10'}
          spacing={'0'}
          display={'inline'}
          textAlign={'left'}
          rightIcon={<HiArrowRight />}
          onClick={onRequestModal}
        >
          WAITLIST
        </Button>
        <VStack justify={'center'} w='full'>
          <VStack
            pos='relative'
            w='90%'
            h='250px'
            justify={'center'}
            overflow={'clip'}
            borderRadius={'md'}
            boxShadow={'1px -1px 20px 5px rgba(0,0,0,0.15)'}
          >
            <VStack
              w='full'
              h='full'
              justify={'center'}
              spacing={'3'}
              pos='absolute'
              opacity={'0.3'}
            >
              <ImageSlider
                style={{
                  ml: '200px'
                }}
              />
              <ImageSlider
                style={{
                  ml: '-260px'
                }}
                nthChild={2}
              />
            </VStack>
            <Text
              fontSize='40px'
              fontWeight='800'
              lineHeight='3.5rem'
              zIndex={'1'}
              // bgGradient={'linear(to-tr, #24ff4c, #24dbff)'}
              // bgClip={'text'}
              textShadow={'2px 2px 8px #00000030'}
            >
              WELCOME TO <br />
              HITMAKR
            </Text>
          </VStack>
          <Box h='5' />
          <Text fontSize='md' w='90%'>
            Unlock the power of the revolution of music with Hitmakr, the
            cutting edge Web3 audio marketplace that connects the gap between
            artists and their audience.
          </Text>
          <Box h='10' />
          <Button
            w='full'
            colorScheme='ghost'
            bg='white'
            color='#7A52EE'
            boxShadow={'0px 2px 11px 3px rgba(0,0,0,0.15)'}
            transition={'all 300ms'}
            _hover={{
              bg: '#212224',
              color: 'white',
              borderColor: 'transparent',
              boxShadow: '-7px 5px 0px 0px rgba(255,255,255,0.75)',
              transform: 'translate(4px, -4px)'
            }}
            role='group'
            size='md'
            fontSize={'sm'}
            rightIcon={<HiArrowRight size={15} />}
            onClick={goToDextools}
          >
            Find HITMAKR on DEXTOOLS
          </Button>
          <Text
            //   bg='white'
            color='white'
            fontSize='xs'
            fontWeight={'semibold'}
            //   px='4'
            //   py='1'
            borderRadius={'md'}
            display={'flex'}
            alignItems={'center'}
          >
            <FaCheck style={{ marginRight: '5px' }} />
            Audited by SourceHat
          </Text>
        </VStack>
      </VStack>
    </>
  )

  const DesktopVersion = (
    <Grid
      minH='100vh'
      w='full'
      // gridTemplateRows={'80px 1fr'}
      gridTemplateRows={'1fr'}
      gridTemplateColumns={'0.5fr 0.5fr'}
      // bgGradient='linear(to-tr, #5a419f, #289d84)'
      bgGradient='linear(to-tr, #000, #124d40)'
      // bg='#252a2b'
    >
      <GridItem
        h='full'
        w='full'
        gridArea={'1 / 1 / 3 / 2'}
        // bgGradient='linear(to-tr, #5a419f, #289d84)'
        // bg='#252a2b'
        display={'flex'}
        justifyContent={'center'}
      >
        <VStack
          justify={'center'}
          h='full'
          color='white'
          w='80%'
          textAlign='left'
          alignSelf={'center'}
          align={'flex-start'}
        >
          <Text fontSize='72px' fontWeight='800' lineHeight='4.5rem'>
            WELCOME TO HITMAKR
          </Text>
          <Text fontSize='md'>
            Unlock the power of the revolution of music with Hitmakr, the
            cutting edge Web3 audio marketplace that connects the gap between
            artists and their audience.
          </Text>
          <Box h='40px' />
          <VStack
            color={'black'}
            bg='white'
            borderWidth={'thin'}
            borderColor='white'
            px='5'
            py='2'
            borderRadius='md'
            align='flex-start'
            transition={'all 300ms'}
            cursor={'pointer'}
            _hover={{
              bg: '#212224',
              color: 'white',
              borderColor: 'white',
              boxShadow: '-7px 5px 0px 0px rgba(255,255,255, 1)',
              transform: 'translate(4px, -4px)'
            }}
            spacing={'0'}
            role='group'
            // onClick={goToDextools}
            onClick={() => window.open('https://nft.hitmakr.io', '_blank')}
          >
            <HStack w='full' spacing={'2'}>
              <Text
                fontSize='xs'
                fontWeight={'semibold'}
                display={'flex'}
                alignItems={'center'}
              >
                Alpha Is Out Now!
              </Text>
              <Box
                w='13px'
                h='13px'
                opacity={'0'}
                transform={'translate(-10px, 0px)'}
                transition={'all 300ms'}
                _groupHover={{
                  opacity: '1',
                  transform: 'translate(0px, 0px)'
                }}
              >
                <HiArrowRight style={{ width: '100%', height: '100%' }} />
              </Box>
            </HStack>
            <Text fontWeight={'semibold'} fontSize={'medium'}>
              Click Here To Access With RaaS Pass NFT
            </Text>
          </VStack>
          <HStack justify={'flex-start'} w='full' spacing={'0'}>
            <Text
              color='white'
              fontSize='xs'
              fontWeight={'semibold'}
              px='4'
              py='1'
              borderRadius={'md'}
              display={'flex'}
              alignItems={'center'}
            >
              <FaCheck style={{ marginRight: '5px' }} />
              Powerd By SKALE
            </Text>
            {/* <Text
              color={'white'}
              fontSize='xs'
              fontWeight={'semibold'}
              px='4'
              py='1'
              borderRadius={'md'}
              display={'flex'}
              alignItems={'center'}
              _hover={{
                textDecor: 'underline'
              }}
              cursor={'pointer'}
              onClick={() =>
                window
                  .open(
                    'https://sourcehat.com/audits/ProofTokenFactoriesWhitelist/',
                    '_blank'
                  )
                  .focus()
              }
            >
              Learn more on PROOF Token Factory
            </Text> */}
          </HStack>
        </VStack>
      </GridItem>
      <GridItem h='full' w='100%' overflow={'hidden'} display={'flex'}>
        <VStack
          w='100%'
          h='full'
          justify={'center'}
          spacing={'3'}
          pos='relative'
        >
          <Image
            className='rotating_vinyl'
            w='full'
            h='full'
            objectFit={'contain'}
            pos='absolute'
            top='0'
            right='0'
            src={VinylImage}
            zIndex={'0'}
            opacity={'0.4'}
            // transform={'rotate3d(1, 1, 1, 45deg)'}
          />
          <VStack
            w='full'
            maxW='500px'
            pos='relative'
            borderRadius='30px'
            borderBottomLeftRadius={'15px'}
            borderBottomRightRadius={'15px'}
            overflow={'clip'}
            color='white'
            boxShadow={'0px 5px 15px 4px rgba(0,0,0,0.25)'}
            role='group'
          >
            <LazyImage
              w='full'
              h='500px'
              bg='black'
              src={
                'https://img.freepik.com/free-vector/flat-geometric-music-youtube-thumbnail-memphis-style_52683-61155.jpg'
              }
              objectFit={'cover'}
              pos='relative'
            />
            <VStack
              pos='absolute'
              top='0'
              left='0'
              h='full'
              w='full'
              bgGradient={'linear(to-t, #000000,  transparent)'}
              justify={'flex-end'}
              align={'flex-start'}
              pb='0'
            >
              <VStack
                pos='absolute'
                top='0'
                left='0'
                h='full'
                w='full'
                bgGradient={
                  'linear(to-t, #000000, #000000, transparent, transparent, transparent)'
                }
                justify={'flex-end'}
                align={'flex-start'}
                pb='0'
                transition={'all 600ms'}
                opacity={'0'}
                _groupHover={{
                  opacity: '1'
                }}
              />
              <VStack
                w='full'
                h='max-content'
                align={'flex-start'}
                px='6'
                py='4'
                zIndex={'1'}
              >
                <Text fontSize={'larger'} fontWeight={'semibold'}>
                  Barracuda's HipHop Playlist
                </Text>
                <Text fontSize={'sm'} fontWeight={'md'}>
                  "Smack That" is a song by Senegalese-American singer Akon
                  featuring rapper Eminem from the former's...
                </Text>
                <HStack
                  zIndex={'1'}
                  color='white'
                  w='full'
                  transition={'all 300ms'}
                  _hover={{
                    borderColor: 'white',
                    boxShadow: '0px 2px 0px 0px rgba(255,255,255, 1)',
                    transform: 'translate(0px, -2px)'
                  }}
                  justifyContent={'flex-start'}
                  py='3'
                  role='group'
                  cursor={'pointer'}
                  onClick={onRequestModal}
                >
                  <Box
                    borderRadius={'full'}
                    bg='white'
                    borderWidth={'thin'}
                    borderColor={'white'}
                    color='black'
                    p='3'
                    transition={'all 300ms'}
                  >
                    <IoMdPlay size={'17'} />
                  </Box>
                  <Text fontWeight={'semibold'}>Access Alpha</Text>
                </HStack>
              </VStack>
            </VStack>

            {/* <HStack w='full' spacing={'7'} px='1'> */}

            {/* </HStack> */}
          </VStack>
        </VStack>
      </GridItem>
    </Grid>
  )

  return isMobile ? MobileVersion : DesktopVersion
}

const Section2 = () => {
  const BG_IMAGE =
    'https://s3-alpha-sig.figma.com/img/2486/ee9a/a1d484d2b9b09cc99138bbecc6d6df52?Expires=1713139200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TMisRAooe454popOB2sFU17~cWSazRmWB7lglBk2J6NNhJ0XzXNJH2kYmfSCzYrMguyB1jfeeHbXBD~fLVbUA0pK8ZfLSwRedVbX5-is5PK0eeicxHE0XWfZGppZ4tKrrJcEnxgD8BShhFVu3aTNeYF2jav-8CC7TZsa8L7fVQb2P1AHOsgkXqqtBO8bAQbuIULnh8NfKbT-hm56hkEjRjBmIG5rNKEtoVh5gw1rhmg84IoLjAR~HSbgDldDYYft4TIU1BrGzvuZxuoCCfxj-Gqz9IFaj9lzf9DfRdQOdHpgqW8eMZQMJqV6Dk3uy-ZISZned1IjN1LM5FqlTi-PVQ__'

  const IS_IT_FOR = [
    {
      icon: <FaTiktok />,
      title: 'TikToker',
      description: 'Brand around your favorite artists, curate playlists',
      gridArea: '3 / 1 / 4 / 2'
    },
    {
      icon: <FaMusic />,
      title: 'I just love music',
      description:
        'Find new scenes and artists, while directly supporting them',
      gridArea: '3 / 2 / 4 / 3'
    },
    {
      icon: <FaUserTie />,
      title: 'Producer',
      description: 'Upload songs for purchase & licensing, create vaults',
      gridArea: '3 / 3 / 4 / 4'
    },
    {
      icon: <FaMicrophoneAlt />,
      title: 'Artist',
      description: 'Upload your music and gain new forms of revenue',
      gridArea: '5 / 1 / 6 / 2'
    },
    {
      icon: <FaGuitar />,
      title: 'Musician',
      description: 'Use instruments to create SFX, loops',
      gridArea: '5 / 2 / 6 / 3'
    },
    {
      icon: <MdOutlineOndemandVideo />,
      title: 'Streamer',
      description:
        'Create gold playlists w/ approved songs for no DMCA takedowns',
      gridArea: '5 / 3 / 6 / 4'
    }
  ]

  return (
    <VStack
      h='max-content'
      w='full'
      color={'white'}
      spacing={'150px'}
      pos='relative'
    >
      <ScrollAnimatedFrame
        pos='absolute'
        h='full'
        top='0'
        left='0'
        right={'0'}
        bottom={'0'}
        zIndex={'0'}
      />
      <Box
        bg='#00000090'
        h='full'
        w='full'
        pos='absolute'
        top='0'
        left='0'
        right={'0'}
        bottom={'0'}
      />
      <VStack
        w='full'
        minH='100vh'
        textAlign={'center'}
        pos={'relative'}
        justify='space-between'
        px='15'
        py='100px'
        zIndex={'1'}
      >
        <VStack w={{ lg: '70%', sm: '90%' }} h='max-content' zIndex={'2'}>
          <Text fontSize='xxx-large' fontWeight='800' lineHeight='3.5rem'>
            WHAT IS HITMAKR?
          </Text>
          <Text fontSize='md' maxW={{ lg: '80%', sm: 'full' }}>
            Hitmakr is focused on generating value through gating, ownership and
            licensing. We aim to create a place where creators can generate
            value through creating audio that their communities can truly own
            and use.
          </Text>
        </VStack>
        <VStack w='full' zIndex={'1'}>
          <Text
            fontSize={{ lg: 'xxx-large', base: 'xx-large' }}
            fontWeight='800'
            lineHeight='3.5rem'
            gridArea={'1 / 1 / 2 / 4'}
            w={'full'}
            textAlign={'center'}
          >
            WHO IS IT FOR?
          </Text>
          <HStack w='full' flexWrap={'wrap'} justify={'center'} spacing={'5'}>
            {IS_IT_FOR.map(item => (
              <Grid
                w='auto'
                gridTemplateRows={'repeat(2, auto)'}
                gridTemplateColumns={'80px 250px'}
                gridColumnGap={'25px'}
                gridArea={item.gridArea}
              >
                <Box
                  display='flex'
                  justifyContent={'center'}
                  alignItems={'center'}
                  bg='#390d58'
                  h='80px'
                  w='80px'
                  gridArea={'1 / 1 / 3 / 2'}
                  borderRadius={'full'}
                  fontSize='20px'
                >
                  {item.icon}
                </Box>
                <Text
                  fontSize='large'
                  fontWeight='semibold'
                  gridArea={'1 / 2 / 2 / 3'}
                  textAlign='left'
                  p='0'
                >
                  {item.title}
                </Text>
                <Text fontSize='sm' gridArea={'2 / 2 / 3 / 3'} textAlign='left'>
                  {item.description}
                </Text>
              </Grid>
            ))}
          </HStack>
        </VStack>
      </VStack>
    </VStack>
  )
}

const FeatureSection = () => {
  const tabs = [
    {
      label: 'MARKETPLACE',
      screen: getTabScreen('MARKETPLACE')
    },
    {
      label: 'AI Tools',
      screen: getTabScreen('AI Tools')
    },
    {
      label: 'FACTORY',
      screen: getTabScreen('FACTORY')
    }
  ]

  function getTabScreen (tabKey) {
    const TabScreenData = {
      MARKETPLACE: {
        title: 'Sell Your Music/Album',
        description:
          'Upload and let your listeners stream your music. Earn by enrolling in the Hitmakr Subscription Plan™ or earn per stream!',
        usp: [
          {
            icon: <FaRegIdBadge />,
            title: 'Licensing',
            description: (
              <>
                <i>Assign A Royalty Wallet</i>
                <br />
                Collect royalties for recorded performances, public performance,
                limited licensing & more, instantly.
                <br />
                <br />
                <i>Play Music Freely*</i>
                <br />
                Put music you enjoy into your own content on other platforms.
              </>
            )
          },
          {
            icon: <FaHandshake />,
            title: 'Support Artists Directly',
            description: (
              <>
                Using blockchain, artists can generate value in subtle but
                substantial ways
                <br />
                <br />
                Explore — Discover new artists and watch them evolve. Exclusive
                Collectibles – Experience the music as the musician intended.
                <br />
                <br />
                ‘Buy Some Streams!’ – Support by showing you’d listen that many
                times if you could!
              </>
            )
          }
        ]
      },
      FACTORY: {
        title: 'Sell Your Beats',
        description:
          'Monetize your Loops, 808s, SFX, and more.Help build unknown soundscapes from the ground up. Bundle your beats and earn more by offering your listeners exciting perks!',
        usp: [
          {
            icon: <GiReceiveMoney />,
            title: 'Derivative Licensing',
            description: (
              <>
                Get Paid When Your Audio is Used. <br />
                <br /> Royalties Paid, Fast.
              </>
            )
          },
          {
            icon: <FaUsers />,
            title: 'Networking',
            description: (
              <>
                Build scenes that will break the mold. Shape a sound and force
                it into the future.
                <br />
                <br /> Create sonic landscapes that amplify your scene's
                identity.
              </>
            )
          }
        ]
      },
      'AI Tools': {
        title: 'AI Tools Usage',
        description:
          'Our AI tools serve as a virtual mentor, providing insights, trends, and personalized strategies that artists typically rely on labels for. They also can generate content in new and innovative ways.',
        usp: [
          {
            icon: <IoStatsChartOutline />,
            title: 'Analytics',
            description: (
              <>
                Assign A Royalty Wallet. Collect royalties for recorded
                performances, public performance, limited licensing & more,
                instantly. Play Music Freely* Put music you enjoy into your own
                content on other platforms.
              </>
            )
          },
          {
            icon: <BsFiletypeMp3 />,
            title: 'Generate Content',
            description: (
              <>
                By utilizing AI-driven analytics, artists can unlock a deeper
                understanding of their audience’s evolving tastes and
                preferences. This real-time data empowers them to make informed
                decisions on everything from musical styles and thematic content
                to engagement strategies, ensuring that their creative endeavors
                resonate more effectively with their target audience.
              </>
            )
          }
        ]
      }
    }

    return TabScreenData[tabKey]
  }

  return (
    <VStack
      px='15'
      py='100px'
      w='full'
      minH='100vh'
      bgGradient={
        'radial(400px 400px at 0% -0%, #1f7964, #226f5c, #26574a, #253c35, #212725, #1e1e1e)'
      }
      color={'white'}
    >
      <Text fontSize='xxx-large' fontWeight='800' lineHeight='3.5rem'>
        FEATURES
      </Text>
      <Box h='40px' />
      <CustomTabs tabData={tabs} />
    </VStack>
  )
}

const TeamSection = () => {
  const [isMobile] = useMediaQuery('(max-width: 1000px)')
  const TEAM_DATA = [
    {
      image:
        'https://e-commerce-test.sgp1.cdn.digitaloceanspaces.com/hitmakr/landing-page/rex.png',
      name: 'Pharaoh',
      designation: 'CEO / Founder'
    },
    {
      image:
        'https://e-commerce-test.sgp1.cdn.digitaloceanspaces.com/hitmakr/landing-page/chrischar-150x150.png',
      name: 'Chris B',
      designation: 'Marketing Team'
    },
    {
      image:
        'https://e-commerce-test.sgp1.cdn.digitaloceanspaces.com/hitmakr/landing-page/ayocharv3-300x300.png',
      name: 'Ayo',
      designation: 'Co-Founder'
    },
    {
      image:
        'https://e-commerce-test.sgp1.cdn.digitaloceanspaces.com/hitmakr/landing-page/image0-1-300x300.png',
      name: 'Marko Visuals',
      designation: 'Marketing'
    }
  ]

  function getImageWidth () {
    return `${(window.screen.width - 30) / 5}px`
  }

  return (
    <VStack
      w='full'
      px='15'
      py='100px'
      minH='100vh'
      color='white'
      //   bgGradient={
      //     'radial(400px 400px at 0% -0%, #1f7964, #226f5c, #26574a, #253c35, #212725, #1e1e1e)'
      //   }
      bg='#1e1e1e'
      pos='relative'
      //   overflowX={'clip'}
      //   overflowY={'visible'}
    >
      {/* <Box
        pos='absolute'
        bgGradient={
          'radial-gradient(50% 50% at 50% 50%,#1f7964, #226f5c, #26574a, #253c35, #212725, #1e1e1e)'
        }
        w='600px'
        h='600px'
        right={'-300px'}
        top='-300px'
        zIndex={'1'}
      /> */}
      <Text fontSize='xxx-large' fontWeight='800' lineHeight='3.5rem'>
        THE TEAM
      </Text>
      <Box h='40px' />

      <SimpleGrid
        h='auto'
        color='white'
        // justify={'space-between'}
        px={{ lg: '10', base: '0' }}
        minChildWidth={'220px'}
        maxChildWidth={'350px'}
        w='full'
        textAlign={'center'}
        // justify={"space-evenly"}
        spacing={{ lg: '5', base: '5' }}
        // flexWrap={{ base: "wrap", lg: "nowrap" }}
        // display={{ lg: 'flex', base: 'inline' }}
        // flexWrap={"wrap"}
      >
        {TEAM_DATA.map((team, index) => (
          <VStack
            h='auto'
            minH='max-content'
            spacing={'5'}
            borderRadius={'16px'}
            bg='#333333'
            pb={{ md: '10', base: '5' }}
            borderBottomWidth={'thin'}
            borderBottomColor={'#2FE2BB'}
            // mt={{ md: index % 2 == 0 ? 0 : 50, base: '0' }}
            transition={'all 300ms'}
            cursor={'pointer'}
            _hover={{
              bg: !isMobile && '#12755f',
              color: !isMobile && 'white',
              transform: !isMobile && 'scale(1.02)'
            }}
          >
            <LazyImage
              src={team.image}
              h={{ md: '250px', base: '200px' }}
              w={{ lg: getImageWidth(), base: 'full' }}
              maxW='240px'
              objectFit={{ md: 'fill', base: 'cover' }}
              objectPosition={'top'}
              borderRadius={'16px'}
            />
            <VStack spacing={'0'}>
              <Text fontSize='lg' fontWeight={'semibold'}>
                {team.name}
              </Text>
              <Text
                fontSize={{ lg: 'xs', base: 'sm' }}
                color='#2FE2BB'
                maxW={{ lg: '140px', base: '95%' }}
                wordBreak={'break-word'}
              >
                {team.designation}
              </Text>
            </VStack>
          </VStack>
        ))}
      </SimpleGrid>
    </VStack>
  )
}

const PartnerSection = () => (
  <VStack
    w='full'
    px='15'
    py='100px'
    minH='max-content'
    color='gray.400'
    bg='#1e1e1e'
    pos='relative'
    spacing={'10'}
  >
    <Text fontSize='large' fontWeight='800' lineHeight='3.5rem'>
      OUR AMAZING PARTNERS
    </Text>
    <HStack
      w='full'
      flexWrap='wrap'
      justify='center'
      spacing={{ md: '40', base: '5' }}
    >
      {[AssureLogo, ProofLogo, SkaleLogo].map(src => (
        <Image
          src={src}
          h={{ md: 'auto', base: '100px' }}
          w={{ md: '200px', base: '70%' }}
          opacity={'0.6'}
          transition={'all 300ms'}
          _hover={{
            opacity: '1'
          }}
          objectFit={'contain'}
        />
      ))}
    </HStack>
  </VStack>
)

const SubscribeSection = () => {
  const [userEmail, setUserEmail] = useState('')
  const joinWaitList = async values => {
    // console.log('values', values)
    try {
      await axios.post(
        'https://backend.hitmakr.antopolis.xyz/api/waitlist',
        values
      )
    } catch (ex) {
      console.log('Failed to add to waitlist:', ex)
    }
  }
  return (
    <VStack
      w='full'
      px='15'
      py='100px'
      pt={{ md: '100px', base: '' }}
      minH='max-content'
      color='gray.400'
      bg='#1e1e1e'
      pos='relative'
      spacing={'20'}
    >
      <Image w='85px' src={WaveIcon} />
      <VStack
        px='15'
        py='10'
        bg='#333333'
        borderRadius={'lg'}
        borderWidth='thin'
        borderColor={'gray.500'}
        w={{ md: '70%', base: 'full' }}
        h='max-content'
        color='white'
        textAlign={'center'}
        spacing={'10'}
      >
        <Text
          fontSize={{ md: 'xx-large', base: 'large' }}
          fontWeight={'semibold'}
        >
          SUBSCRIBE TO OUR EMAIL LIST
        </Text>
        <InputGroup
          w={{ md: '50%', base: 'full' }}
          bg='#414141'
          borderRadius='md'
        >
          <Input
            placeholder='Your Email'
            border='0'
            outline={'none'}
            value={userEmail}
            onChange={e => setUserEmail(e.target.value)}
          />
          <InputRightElement w='max-content' p='1'>
            <Button
              size='sm'
              w='max-content'
              colorScheme='ghost'
              bg='white'
              color='#7A52EE'
              boxShadow={'0px 0px 11px 3px rgba(0,0,0,0.15)'}
              transition={'all 300ms'}
              _hover={{
                bg: '#212224',
                color: 'white',
                borderColor: 'transparent'
                //   boxShadow: '-7px 0px 0px 0px rgba(255,255,255,0.75)'
                //   transform: 'translate(4px, -4px)'
              }}
              onClick={() => joinWaitList({ email: userEmail })}
              role='group'
              rightIcon={
                <Box
                  w='0px'
                  opacity={'0'}
                  transform={'translate(-10px, 0px)'}
                  transition={'all 300ms'}
                  _groupHover={{
                    opacity: '1',
                    transform: 'translate(0px, 0px)',
                    width: '13px'
                  }}
                >
                  <HiArrowRight size={15} />
                </Box>
              }
            >
              SUBSCRIBE
            </Button>
          </InputRightElement>
        </InputGroup>
      </VStack>
    </VStack>
  )
}

const FooterSection = () => {
  const SOCIAL_LINKS = [
    {
      icon: <FaTwitter />,
      link: 'https://twitter.com/hitmakrr'
    },
    {
      icon: <FaTelegram />,
      link: 'https://t.me/Hitmakr_Portal'
    },
    {
      icon: <FaInstagram />,
      link: ''
    }
  ]

  function goToSocial (social) {
    window.open(social.link, '_blank').focus()
  }
  return (
    <HStack w='full' justify={'space-between'} px='35' py='30' color='white'>
      <Text fontSize={'sm'}>
        Copyright © 2023. All rights reserved by{' '}
        <b style={{ color: '#2FE2BB' }}>HITMAKR</b>
      </Text>
      <HStack w='max-content' spacing={'5'}>
        {SOCIAL_LINKS.map((social, index) => (
          <VStack
            key={`footer_section_social_${index}`}
            bg='#2FE2BB'
            fontSize='20'
            color='black'
            p='2'
            borderRadius={'full'}
            onClick={() => goToSocial(social)}
            cursor={'pointer'}
            transition={'all 300ms'}
            _hover={{
              bg: '#2FE2BB70'
            }}
          >
            {social.icon}
          </VStack>
        ))}
      </HStack>
    </HStack>
  )
}

export const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <VStack w='full' h='max-content' spacing={'0'} bg='#1e1e1e'>
      <Navbar />
      <WaitListForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <HeroSection onRequestModal={() => window.open('https://player.hitmakr.io', '_blank')} />
      <Section2 />
      <FeatureSection />
      <TeamSection />
      <PartnerSection />
      <SubscribeSection />
      <Divider borderColor={'gray.500'} />
      <FooterSection />
    </VStack>
  )
}

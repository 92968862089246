import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import React from "react";
import { Field, Form } from "formik";

const TokenomicsSubscribe = () => {
  return (
    <Container
      as={"section"}
      maxW={"1140px"}
      border={{ base: "none", md: "1px solid rgba(255, 255, 255, 0.25)" }}
      bg={"darkColor.800"}
      borderRadius={"1rem"}
      p={"1rem"}
      py={"2.5rem"}
      textAlign={"center"}
      bg={{ base: "transparent", md: "darkColor.800" }}
      color={"white"}
      mb={"5.5rem"}
    >
      <Heading
        as={"h3"}
        fontSize={{ base: "1.5rem", md: "2.9rem" }}
        lineHeight={""}
        mb={"2rem"}
        maxW={"424px"}
        marginLeft={"auto"}
        marginRight={"auto"}
      >
        {" "}
        Subscribe to our email list
      </Heading>

      <Box
        as="form"
        bg={"darkColor.600"}
        p={".4rem"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        maxW={"660px"}
        marginLeft={"auto"}
        marginRight={"auto"}
        borderRadius={"8px"}
      >
        <FormControl>
          <Input
            placeholder="Email address"
            name="name"
            border={"none"}
            _placeholder={{ color: "white" }}
          />
        </FormControl>
        <Button
          colorScheme="teal"
          type="submit"
          bgGradient="linear(to-r, #764FEA, #31DAB9)"
          size={"lg"}
          minW={{ base: "100px", md: "160px" }}
        >
          Submit
        </Button>
      </Box>
    </Container>
  );
};

export default TokenomicsSubscribe;

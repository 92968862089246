import { VStack } from '@chakra-ui/react'
import { RouterProvider } from 'react-router-dom'
import './App.css'
import { MainRouter } from './pages/routes'
function App ({ children }) {
  return (
    <VStack id='parent' w='full' h='full' overflowX={'none'}>
      <VStack h='max-content' w='full' fontFamily={'Poppins'}>
        <RouterProvider router={MainRouter}>{children}</RouterProvider>
      </VStack>
    </VStack>
  )
}

export default App
